import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
    userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem("userInfo")) : null,
}

const AuthSlicer = createSlice({
    name: 'userSlice',
    initialState,

    reducers: {
        userData: (state, action) => {
            state.userInfo = action.payload;
            localStorage.setItem("userInfo", JSON.stringify(action.payload));
        },
        userLogOut: (state, action) => {
            state.userInfo = null;
            localStorage.removeItem("userInfo");
        }
    }
});

export const { userData, userLogOut } = AuthSlicer.actions

export default AuthSlicer.reducer