import './App.css';
import LoginPage from './pages/LoginPage';
import Home from './pages/Home';
import Otp from './pages/Otp';

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import { MyProvider } from './utils/context/MyContext';
import AuthUserProtector from './protuctors/UserProtected';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/login' element={<LoginPage />} />
          <Route path='/otp' element={<Otp />} />
          <Route path='/' element={<Home />} />
          <Route path='*' element={<Navigate to="/login" replace />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
