import axios from "axios";
import { BaseURL } from "../endpoints/Url";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";


const Instance = axios.create({
    baseURL: BaseURL,
    headers: {
        'Content-Type': 'application/json',
    }
});

let isRefreshing = false;
let refreshSubscribers = [];


function onRrefreshed(token) {
    refreshSubscribers.map(callback => callback(token));
}

function addRefreshSubscriber(callback) {
    refreshSubscribers.push(callback);
}

Instance.interceptors.request.use(
    (config) => {
        // const accessToken = Cookies.get('accessToken'); // Retrieve your token from storage
        const accessToken = localStorage.getItem("e-c_accessToken");
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        } else {
            // Handle the case where access token is initially undefined
            console.log("Access token not yet available in  local store");
        }

        console.log(config);
        return config;
    },
    (error) => {
        console.log(error + "errorone");
        return Promise.reject(error);
    }
);

Instance.interceptors.response.use(
    (response) => {
        console.log(response);
        return response;
    },
    (error) => {
        const {
            config,
            response: { status }
        } = error;


        const originalRequest = config;
        console.log(status, originalRequest._retry)

        if (status === 401 && !originalRequest._retry) {
            console.log("i a m here")
            if (!isRefreshing) {
                console.log("i a m here")
                isRefreshing = true;
                originalRequest._retry = true;

                return new Promise((resolve, reject) => {
                    Instance.post(`${BaseURL}/client_auth/refresh/tokens/`, {
                        refresh_token: localStorage.getItem("e-c_refreshToken")
                    })
                        .then(({ data }) => {
                            console.log(data)
                            Cookies.set('accessToken', data.access_token);
                            localStorage.setItem("e-c_accessToken", data?.access_token);
                            Instance.defaults.headers['Authorization'] = 'Bearer ' + data.access_token;
                            originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
                            onRrefreshed(data.access_token);
                            resolve(Instance(originalRequest));
                        })
                        .catch((err) => {
                            console.log(err)
                            reject(err);
                        })
                        .finally(() => {
                            isRefreshing = false;
                        });
                });
            }

            return new Promise((resolve, reject) => {
                addRefreshSubscriber(token => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    resolve(Instance(originalRequest));
                });
            });
        }

        console.log(error?.response?.status);
        console.log(error?.config);
        // Handle error responses globally here
        return Promise.reject(error);
    }
);

export default Instance;
