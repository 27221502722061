// export const BaseURL = "https://sapadminportal.online"
export const BaseURL = "https://server.sapadminportal.online"
// export const BaseURL = "https://43a8-103-183-83-139.ngrok-free.app"

export const OTPSend = '/client_auth/send_otp/'
export const VerifyOTP = '/client_auth/verify_otp/'
export const GetUserData = '/student_dashboard/get_student_details/'
export const DailyClass = '/class/updates/client/get/link/'
export const PreviousClass = '/class/updates/recordings/dates/client/'
export const GetYtLink = '/class/updates/recordings/client/web/get/'
export const emailSend = '/client_auth/send_otp_email/'
export const emailVerification = '/client_auth/verify_otp_email/'
export const logout = '/client_auth/logout/'