import { configureStore } from "@reduxjs/toolkit";
import AuthSlicer from "./slice/AuthSlicer";

const initialState = {}

const store = configureStore({
    reducer: {
        auth: AuthSlicer
    }
})

export default store;
