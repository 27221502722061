import React, { useEffect, useState } from "react";
import { GetYtLink, PreviousClass } from "../utils/endpoints/Url";
import Instance from "../utils/axios/Instance";
import YouTube from "react-youtube";
import { GrClose } from "react-icons/gr";

const PreviousClasses = () => {
  const [dates, setDates] = useState([]);
  const [videoLink, setVideoLink] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [video, setVideo] = useState(false);

  useEffect(() => {
    Instance.get(PreviousClass)
      .then((res) => {
        setDates(res?.data?.dates);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const changeHandler = async (e) => {
    const date = e.target.value;
    if (date) {
      try {
        const response = await Instance.get(`${GetYtLink}?date=${date}`);
        setVideoLink(response?.data?.recorded_classes);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const playVideo = (video) => {
    setSelectedVideo(video);
    setVideo(true);
  };
  const opts = {
    height: "450", // Set your desired height here
    width: "740",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div>
      <div className="flex items-center justify-between md:mt-5 mb-2">
        <h1 className="font-bold my-5 text-2xl">Previous Class</h1>
        <select
          onChange={changeHandler}
          name="date"
          id=""
          className="border border-slate-600 p-2 px-4 rounded focus:outline-none text-sm font-medium "
        >
          <option value="" className="text-xs">
            Select A Date
          </option>
          {dates?.map((items, i) => (
            <option key={i} value={items} className="text-xs">
              {items}
            </option>
          ))}
        </select>
      </div>
      <div className="flex flex-col md:flex-row flex-wrap gap-4 ">
        {videoLink &&
          videoLink?.map((items, i) => (
            <div key={i} className="my-3 cursor-pointer py-2">
              <h1 className="font-semibold mb-3">{items.subject}</h1>
              <div className="bg-white border shadow relative flex items-center justify-center w-[280px] p-5 rounded-lg mb-10">
                <img
                  src={require("../assets/bg.png")}
                  alt="play_button"
                  className="w-50 absolute rounded-lg"
                />
                <button onClick={() => playVideo(items)} className="absolute">
                  <img
                    src={require("../assets/375.png")}
                    alt="play_button"
                    className="w-20  hover:scale-105 ease-in-out duration-200 cursor-pointer"
                  />
                </button>
              </div>
            </div>
          ))}
      </div>
      {video && (
        <div className="fixed w-screen h-screen bg-black/50 z-50 left-0  top-0 flex justify-center items-center">
          <div className="relative ">
            <GrClose
              size={20}
              className="bg-white absolute right-0 cursor-pointer rounded-md p-1 hover:text-[#F2871D]"
              onClick={(e) => setVideo(false)}
            />
            {selectedVideo && (
              <YouTube
                opts={opts}
                videoId={selectedVideo.video_id}
                className=""
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PreviousClasses;
