import React, { useState, useEffect, useRef } from "react";
import { VerifyOTP, emailVerification } from "../utils/endpoints/Url";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { userData } from "../utils/context/slice/AuthSlicer";
import { useDispatch } from "react-redux";

import Instance from "../utils/axios/Instance";
import Cookies from "js-cookie";

const Otp = ({ numInputs, phone_number, setOtpModal, isType, email }) => {
  const [otpValues, setOtpValues] = useState(Array(numInputs).fill(""));
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, numInputs);
  }, [numInputs]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    const updatedOtpValues = [...otpValues];
    updatedOtpValues[index] = value;
    setOtpValues(updatedOtpValues);

    if (value && index < numInputs - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    const joining = otpValues.join("");
    try {
      let response;
      if (isType === "phone") {
        response = await Instance.post(VerifyOTP, {
          otp: joining,
          phone_number: phone_number,
        });
      } else if (isType === "email") {
        response = await Instance.post(emailVerification, {
          otp: joining,
          email: email,
        });
      }
      if (response?.status === 200) {
        console.log(response);
        dispatch(
          userData({
            name: response?.data?.name,
            class_name: response?.data?.class_name,
            division: response?.data?.division,
            batch_year: response?.data?.batch_year,
          })
        );
        Cookies.set("accessToken", response?.data?.access_token);
        Cookies.set("refreshToken", response?.data?.refresh_token);
        localStorage.setItem("e-c_accessToken", response?.data?.access_token);
        localStorage.setItem("e-c_refreshToken", response?.data?.refresh_token);
        navigate("/");
        setOtpModal(false);
      }
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  };

  return (
    <div className="fixed z-10 top-0 w-full h-screen bg-black/50 flex flex-col items-center justify-center">
      <div className="bg-white rounded-lg p-5 flex flex-col items-center">
        <h1 className="flex font-bold">Enter Verification Code</h1>
        <form
          onSubmit={submitHandler}
          action=""
          className="flex flex-col items-center"
        >
          <div className="flex gap-2 mt-5">
            {Array.from({ length: numInputs }).map((_, index) => (
              <input
                key={index}
                type="number"
                className="border w-[50px] p-2 rounded"
                value={otpValues[index]}
                onChange={(e) => handleChange(e, index)}
                ref={(el) => (inputRefs.current[index] = el)}
                maxLength={1}
              />
            ))}
          </div>
          <button className="bg-[#EA454C] w-full p-2 rounded-lg text-white text-sm my-5">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Otp;
