import React, { useContext, useState } from "react";
import { IoMdLogOut } from "react-icons/io";
import Instance from "../utils/axios/Instance";
import { logout } from "../utils/endpoints/Url";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MyContext from "../utils/context/MyContext";
import { useDispatch } from "react-redux";
import { userLogOut } from "../utils/context/slice/AuthSlicer";

const Nav = ({ user }) => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutHandler = async () => {
    setLoader(true);
    dispatch(userLogOut());
    try {
      const response = await Instance.post(logout);
      console.log(response)
      toast.success(response?.data?.message);
      navigate("/login");
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  return (
    <nav className="container mx-auto p-2 md:p-3 max-w-[1280px]">
      <div className="flex items-center justify-between md:px-5">
        <div className="">
          <Link to={"/home"}>
            <img
              src={require("../assets/e-cube logo.png")}
              alt="logo"
              className="w-32"
            />
          </Link>
        </div>
        <div className="flex items-center gap-4 ">
          <IoMdLogOut onClick={logoutHandler} size={25} className="cursor-pointer" />
          <div className="flex flex-col">
            <img
              src={require("../assets/User-avatar.svg.png")}
              alt="user"
              className="w-7"
            />
            <h1 className="font-bold capitalize">{user?.name}</h1>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
