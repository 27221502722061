import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import Otp from "./Otp";
import Instance from "../utils/axios/Instance";
import { OTPSend, emailSend } from "../utils/endpoints/Url";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import MyContext from "../utils/context/MyContext";

const LoginPage = () => {
  const [otpModal, setOtpModal] = useState(false);
  const [phone_number, setPhoneNumber] = useState("");
  const [email, setEmail] = useState();
  const [method, setMethod] = useState("");
  const userData = useSelector((state) => state?.auth?.userInfo);
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      console.log(phone_number);
      const response = await Instance.post(OTPSend, { phone_number });
      if (response?.status === 200) {
        setOtpModal(true);
        console.log(response?.data)
        toast.success(response?.data?.message);
      }
      console.log(response);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message);
    }
  };

  const emailSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await Instance.post(emailSend, { email });
      if (response?.status === 200) {
        setOtpModal(true);
        console.log(response?.data)
        toast.success(response?.data?.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Please Try Again Later");
    }
  };

  useEffect(() => {
    if (userData !== null) {
      navigate("/");
    }else{
      navigate('/login')
    }
  }, [userData, navigate]);

  return (
    <>
      <div className="container mx-auto">
        <div className="w-full h-screen md:flex items-center justify-around">
          <div className=" w-full flex flex-col justify-center items-center">
            <img
              src={require("../assets/e-cube logo.png")}
              alt="logo"
              className="object-cover w-48"
            />
            <div className="flex flex-col items-center justify-center">
              <h1 className="text-3xl font-bold ">Welcome back</h1>
              <p>Welcome back! Please enter your details.</p>
            </div>

            <div className="w-full md:w-1/2 items-center justify-center px-10 mt-5">
              <p className="font-bold text-center text-xl text-slate-600 ">
                Select Login Method
              </p>
              <div className="flex justify-center gap-5 w-full mt-3">
                <div className="flex  items-center justify-center gap-2">
                  <label htmlFor="email">Email</label>
                  <input
                    id="email"
                    name="method"
                    value="email"
                    type="radio"
                    onChange={(e) => setMethod(e.target.value)}
                  />
                </div>
                <div className="flex  items-center justify-center gap-2">
                  <label htmlFor="ph">Phone</label>
                  <input
                    id="ph"
                    name="method"
                    type="radio"
                    value="phone"
                    onChange={(e) => setMethod(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {method === "email" ? (
              <form
                action=""
                onSubmit={emailSubmitHandler}
                className="flex flex-col items-start w-full md:w-1/2 px-10 mt-5"
              >
                <label htmlFor="" className="mb-2 font-semibold text-slate-600">
                  Email
                </label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  name="email"
                  placeholder="Enter Your Email"
                  required
                  className="border focus:outline-none w-full p-2 rounded-lg mb-5"
                />
                <button className="bg-[#EA454C] w-full p-2 rounded-lg text-white">
                  Sign in
                </button>
                <div className="flex justify-center w-full mt-3">
                  <span className="text-sm text-slate-600">
                    Developed by{" "}
                    <Link
                      to="https://qmarktechnolabs.com/"
                      className="hover:text-blue-700"
                    >
                      Qmark Technolabs
                    </Link>{" "}
                  </span>
                </div>
              </form>
            ) : method === "phone" ? (
              <form
                action=""
                onSubmit={submitHandler}
                className="flex flex-col items-start w-full md:w-1/2 px-10 mt-5"
              >
                <label htmlFor="" className="mb-2 font-semibold text-slate-600">
                  Enter Your Phone Number
                </label>
                <input
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  type="string"
                  name="phone_number"
                  required
                  placeholder="Enter Your Phone Number"
                  className="border focus:outline-none w-full p-2 rounded-lg mb-5"
                />
                <button className="bg-[#EA454C] w-full p-2 rounded-lg text-white">
                  Sign in
                </button>

                <div className="flex justify-center w-full mt-3">
                  <span className="text-sm text-slate-600">
                    Developed by{" "}
                    <Link
                      to="https://qmarktechnolabs.com/"
                      className="hover:text-blue-700"
                    >
                      Qmark Technolabs
                    </Link>{" "}
                  </span>
                </div>
              </form>
            ) : (
              ""
            )}
          </div>
          <div className="w-full">
            <img src={require("../assets/side 3d.png")} alt="" />
          </div>
        </div>
      </div>
      {otpModal && (
        <Otp
          phone_number={phone_number}
          email={email}
          numInputs={6}
          setOtpModal={setOtpModal}
          isType={method}
        />
      )}
    </>
  );
};

export default LoginPage;
