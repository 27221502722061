import React, { useEffect, useState } from "react";
import Instance from "../utils/axios/Instance";
import { DailyClass } from "../utils/endpoints/Url";

const DailyClassLink = ({ user }) => {
  const [data, setData] = useState({});
  const [currentDate, setCurrentDate] = useState("");
  
  useEffect(() => {
    const date = new Date();
    const formattedDate = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
    setCurrentDate(formattedDate);
    Instance.get(DailyClass)
      .then((res) => {
        console.log(res)
        setData(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  console.log(user)

  return (
    <div className="flex w-full h-full ">
      <div className="flex items-center justify-center w-full ">
        <img
          src={require("../assets/banner 2.jpg")}
          alt="banner"
          className="object-cover w-full h-full md:h-[600px] absolute left-0 "
        />
        <div className=" text-white w-full flex flex-col  items-start justify-center pt-40  z-20">
          <span className="font-bold md:my-2 text-3xl md:text-[40px]">
            Hi <span className="text-[#F2871D] capitalize">{user?.name},</span>{" "}
          </span>
          <br className="md:hidden" />
          <span className="font-bold md:my-2 text-2xl md:text-[40px]">
            Welcome to the classroom
          </span>
          <h1 className="font-semibold md:mt-3">
            Date : {currentDate}
            <span className="text-sm font-normal shadow capitalize">
              {data?.date}
            </span>
          </h1>
          <div className="flex flex-wrap md:flex-row  gap-2 md:gap-5 mt-3 ">
            {data?.class_links?.length > 0 ? (
              data?.class_links.map((items,i) => (
                <div key={i} className="">
                  <h1 className="text-xs capitalize mb-1">{items?.topic}</h1>
                  <a target="blank" href={items?.link}>
                    <button className="p-2 px-4 bg-[#F2871D] text-white text-sm">
                      Join Class Now
                    </button>
                  </a>
                </div>
              ))
            ) : (
              <data>
                <h1 className="font-semibold">
                  Note :{" "}
                  <span className="text-sm font-normal shadow ">
                    Today, no classes are available
                  </span>
                </h1>
              </data>
            )}
          </div>
          <div className="font-bold my-2 text-2xl md:text-[35px] mt-3">
            {data?.announcement ? data?.announcement : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyClassLink;
