import React, { useContext, useEffect, useState } from "react";
import Instance from "../utils/axios/Instance";
import { GetUserData } from "../utils/endpoints/Url";
import Nav from "../components/Nav";
import DailyClassLink from "../components/DailyClassLink";
import PreviousClasses from "../components/PreviousClasses";
import Footer from "../components/Footer";
import MyContext from "../utils/context/MyContext";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Home = () => {
  const [user, setUser] = useState({});
  const userData = useSelector((state) => state?.auth?.userInfo);
  const navigate = useNavigate();

  useEffect(() => {
    Instance.get(GetUserData)
      .then((res) => {
        console.log(res)
        setUser(res?.data?.student_info);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (userData !== null) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div className="relative min-h-screen">
      <div className="shadow fixed top-0 right-0 z-50 bg-white w-full ">
        <Nav user={user} />
      </div>
      <div className="p-3 h-full relative">
        {/* Profile */}
        <div className="w-full overflow-hidden max-w-[1280px] mx-auto bg-red-500">
          <DailyClassLink user={user} />
        </div>
      </div>
      <div className="container mx-auto p-3 mb-5 max-w-[1200px] h-full md:mt-20 ">
        <div className="min-h-[350px] ">
          <PreviousClasses />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
